exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bio-francais-js": () => import("./../../../src/pages/bio-francais.js" /* webpackChunkName: "component---src-pages-bio-francais-js" */),
  "component---src-pages-bio-js": () => import("./../../../src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-tour-js": () => import("./../../../src/pages/tour.js" /* webpackChunkName: "component---src-pages-tour-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-chart-archive-js": () => import("./../../../src/templates/ChartArchive.js" /* webpackChunkName: "component---src-templates-chart-archive-js" */),
  "component---src-templates-format-archive-js": () => import("./../../../src/templates/FormatArchive.js" /* webpackChunkName: "component---src-templates-format-archive-js" */),
  "component---src-templates-music-archive-js": () => import("./../../../src/templates/MusicArchive.js" /* webpackChunkName: "component---src-templates-music-archive-js" */)
}

